import React from "react"
import { graphql, ScriptStrategy } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Layout from '../../components/layout'
import sue_vdh_pic from '../../images/sue_vdh_pic.jpg'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { Figure } from "react-bootstrap"


const sciteam = [
  {
    name: "Scott Braun", affiliation: "Goddard Space Flight Center, NASA", imageId: "scott_braun_pic",
    incus_position: "Co-Investigator", institutional_position: "Research Meteorologist"
  },
  {
    name: "Shannon Brown", affiliation: "Jet Propulsion Laboratory, NASA", imageId: "shannon_brown_pic",
    incus_position: "Co-Investigator", institutional_position: "Senior Technologist"
  },
  {
    name: "Jennie Bukowski", affiliation: "Colorado State University", imageId: "blank_person",
    incus_position: "", institutional_position: "Postdoctoral Fellow"
  },
  {
    name: "Randy Chase", affiliation: "Colorado State University", imageId: "randy_chase_pic",
    incus_position: "", institutional_position: "Research Scientist I"
  },

  {
    name: 'Brenda Dolan', affiliation: "Colorado State University", imageId: "brenda_dolan_pic",
    incus_position: "", institutional_position: "Research Scientist"
  },
  {
    name: 'Sean Freeman', affiliation: "The University of Alabama in Huntsville", imageId: "sean_freeman_pic",
    incus_position: "", institutional_position: "Assistant Professor"
  },
  {
    name: 'Patrick Gatlin', affiliation: "Marshall Space Flight Center, NASA", imageId: "patrick_gatlin_pic",
    incus_position: "", institutional_position: "Research Meteorologist"
  },
  {
    name: 'Leah Grant', affiliation: "Colorado State University", imageId: "leah_grant_pic",
    incus_position: "", institutional_position: "Research Scientist"
  },
  {
    name: 'George Huffman', affiliation: "Goddard Space Flight Center, NASA", imageId: "george_huffman_pic",
    incus_position: "", institutional_position: "Research Physical Scientist"
  },

  {
    name: "Pavlos Kollias", affiliation: "Stony Brook University", imageId: "pavlos_kollias_pic",
    incus_position: "Co-Investigator", institutional_position: "Professor"
  },
  {
    name: "Mark Kulie", affiliation: "NOAA", imageId: "mark_kulie_pic",
    incus_position: "Co-Investigator", institutional_position: "Research Scientist"
  },
  {
    name: 'Gabrielle Bee Leung', affiliation: "Colorado State University", imageId: "gabrielle_leung_pic",
    incus_position: "", institutional_position: "PhD Candidate"
  },

  {
    name: 'Z. “Johnny” Luo', affiliation: "CCNY", imageId: "johnny_luo_pic",
    incus_position: "Co-Investigator", institutional_position: "Professor"
  },
  {
    name: "Gerald Mace", affiliation: "University of Utah", imageId: "jay_mace_pic",
    incus_position: "Co-Investigator", institutional_position: "Professor"
  },
  {
    name: 'Peter Marinescu', affiliation: "Colorado State University", imageId: "peter_marinescu_pic",
    incus_position: "", institutional_position: "Research Scientist"
  },
  {
    name: "Mary Morris", affiliation: "Jet Propulsion Laboratory, NASA", imageId: "blank_person",
    incus_position: "", institutional_position: "Technologist"
  },

  {
    name: 'Walter Petersen', affiliation: "Marshall Space Flight Center, NASA", imageId: "walt_petersen_pic",
    incus_position: "Co-Investigator", institutional_position: "Chief, Science Research and Projects Division"
  },
  {
    name: 'Derek Posselt', affiliation: "Jet Propulsion Laboratory, NASA", imageId: "derek_posselt_pic",
    incus_position: "Co-Investigator", institutional_position: "Research Scientist"
  },
  {
    name: 'Sai Prasanth', affiliation: "Jet Propulsion Laboratory, NASA", imageId: "sai_prasanth_pic",
    incus_position: "", institutional_position: "NASA Postdoctoral Program Fellow"
  },
  {
    name: 'Kristen Rasmussen', affiliation: "Colorado State University", imageId: "kristen_rasmussen_pic",
    incus_position: "Co-Investigator", institutional_position: "Associate Professor"
  },
  {
    name: 'Steve Reising', affiliation: "Colorado State University", imageId: "steve_reising_pic",
    incus_position: "Co-Investigator", institutional_position: "Professor"
  },
  {
    name: 'Rick Schulte', affiliation: "Colorado State University", imageId: "rick_schulte_pic",
    incus_position: "", institutional_position: "Postdoctoral Fellow"
  },

  {
    name: 'Courtney Schumacher', affiliation: "Texas A&M University", imageId: "courtney_schumacher_pic",
    incus_position: "Co-Investigator", institutional_position: "Professor"
  },
  {
    name: 'Itinderjot Singh', affiliation: "Colorado State University", imageId: "blank_person",
    incus_position: "", institutional_position: "Postdoctoral Fellow"
  },

  {
    name: 'Graeme Stephens', affiliation: "Jet Propulsion Laboratory, NASA", imageId: "graeme_stephens_pic",
    incus_position: "Co-Investigator", institutional_position: "Director of the Center for Climate Sciences"
  },
  {
    name: 'Rachel Storer', affiliation: "UCLA/JIFRESSE", imageId: "rachel_storer_pic",
    incus_position: "", institutional_position: "Associate Project Scientist"
  },
  {
    name: 'Ousmane Sy', affiliation: "Jet Propulsion Laboratory, NASA", imageId: "ousmane_sy_pic",
    incus_position: "", institutional_position: "Engineer"
  },
  {
    name: 'Hanii Takahashi', affiliation: "Jet Propulsion Laboratory, NASA", imageId: "hanii_takahashi_pic",
    incus_position: "", institutional_position: "Scientist"
  }


];

const intl_collabs = [
  {
    name: 'Alessandro Battaglia', affiliation: "University of Leicester", imageId: "alessandro_battaglia_pic",
    institutional_position: "Associate Professor"
  },
  {
    name: 'Richard Forbes', affiliation: "ECMWF", imageId: "richard_forbes_pic",
    institutional_position: "Senior Scientist"
  },
  {
    name: 'Christopher Holloway', affiliation: "University of Reading", imageId: "christopher_holloway_pic",
    institutional_position: "Associate Professor"
  },
  {
    name: 'Alain Protat', affiliation: "Australian Bureau of Meterology", imageId: "alain_protat_pic",
    institutional_position: "Senior Principal Research Scientist"
  },
  {
    name: 'Thota Narayana Rao', affiliation: "NARL", imageId: "tn_rao_pic",
    institutional_position: "Head, Clouds and Convective Systems Group"
  },
  {
    name: 'Rémy Roca', affiliation: "CNRS", imageId: "remy_roca_pic",
    institutional_position: "Senior Researcher"
  },
  {
    name: 'Philip Stier', affiliation: "University of Oxford", imageId: "philip_stier_pic",
    institutional_position: "Professor"
  },
  {
    name: 'Francisco J. Tapiador', affiliation: "University of Castilla-La Mancha", imageId: "francisco_tapiador_castilla_pic",
    institutional_position: "Professor"
  },

  {
    name: 'Daniel Vila', affiliation: "INPE", imageId: "daniel_vila_pic",
    institutional_position: "Research Associate"
  },
];

const advisory_board = [
  {
    name: 'Christian Jakob', affiliation: "Monash University", imageId: "christian_jakob_pic",
    institutional_position: "Professor"
  },
  {
    name: 'John Knaff', affiliation: "NOAA", imageId: "john_knaff_pic",
    institutional_position: "Physical Scientist"
  },
  {
    name: 'Mitchell Moncrieff', affiliation: "NCAR", imageId: "mitchell_moncrieff_pic",
    institutional_position: "Senior Scientist"
  },
  {
    name: 'David Romps', affiliation: "University of California, Berkeley", imageId: "david_romps_pic",
    institutional_position: "Professor"
  },
  {
    name: 'Wei-Kuo Tao', affiliation: "Goddard Space Flight Center, NASA", imageId: "wei-kuo_tao_pic",
    institutional_position: "Senior Research Scientist"
  },

];


interface PersonImages {
  [key: string]: { imageData, alt_text: string }
};

function convert_query_to_keyval(imageList) {
  //console.log(imageList.allPeopleImageListJson);
  var image_output: PersonImages = {};
  imageList.allPeopleImageListJson.nodes.forEach(element => {
    //console.log(element.slug);
    image_output[element.slug] = { alt_text: element.alt_text, imageData: getImage(element.path) };

  });
  return image_output;

}

function render_sciteam(sciteam, imageList) {
  var output: JSX.Element[] = [];
  const image_output = convert_query_to_keyval(imageList);
  sciteam.forEach(member => {
    var output_val = <Card key={member.name}>
      <div className="card-img-top">
        <GatsbyImage
          image={image_output[member.imageId].imageData}
          alt={image_output[member.imageId].alt_text}
        />
      </div>

      <Card.Title>{member.name}</Card.Title>
      <Card.Body>{member.institutional_position}<br />{member.affiliation}</Card.Body>
    </Card>
    output.push(
      output_val
    );

  });
  return output;
}
function render_intl_collabs(intl_collabs, imageList) {
  /*
  Function to render the international collaborators 
  */
  var output: JSX.Element[] = [];
  const image_output = convert_query_to_keyval(imageList);
  intl_collabs.forEach(member => {
    var output_val = <Card key={member.name}>
      <div className="card-img-top">
        <GatsbyImage
          image={image_output[member.imageId].imageData}
          alt={image_output[member.imageId].alt_text}
        />
      </div>

      <Card.Title>{member.name}</Card.Title>
      <Card.Body>{member.institutional_position}<br />{member.affiliation}</Card.Body>
    </Card>
    output.push(
      output_val
    );

  });
  return output;
}

function render_advisory_board(advisory_board, imageList) {
  /*
  Function to render the advisory board 
  */
  var output: JSX.Element[] = [];
  const image_output = convert_query_to_keyval(imageList);
  advisory_board.forEach(member => {
    var output_val = <Card key={member.name}>
      <div className="card-img-top">
        <GatsbyImage
          image={image_output[member.imageId].imageData}
          alt={image_output[member.imageId].alt_text}
        />
      </div>

      <Card.Title>{member.name}</Card.Title>
      <Card.Body>{member.institutional_position}<br />{member.affiliation}</Card.Body>
    </Card>
    output.push(
      output_val
    );

  });
  return output;
}


const intl_collabs_render = intl_collabs.map((member) =>
  <Card>
    <Card.Title>{member.name}</Card.Title>
    <Card.Body>{member.affiliation}</Card.Body>
  </Card>
);

const advisory_board_render = advisory_board.map((member) =>
  <Card>
    <Card.Title>{member.name}</Card.Title>
    <Card.Body>{member.affiliation}</Card.Body>
  </Card>
);


const MissionTeamPage = ({ data }) => {

  return (
    <Layout pageTitle="INCUS Team">
      <h1>The Team</h1>
      <h4>
        The INCUS team is comprised of scientists and engineers from 11 universities, 3 NASA laboratories, 2 industry partners, and 7 other meteorological centers, institutes and organizations.
      </h4>
      <Figure>
        <StaticImage
          alt="A picture of the INCUS Science Team"
          src="../../images/incus_stm_2022.jpg"
          layout="constrained"
          placeholder="blurred"
          objectPosition="0 0"

        />
        <figcaption>A team picture taken at the first INCUS Science Team Meeting held on 11-13 October at Colorado State University</figcaption>
      </Figure>


      <h2>Leadership</h2>
      <Row xs={1} md={5} className='g-4'>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A picture of Sue van den Heever"
              src="../../images/people/sue_vdh_pic.jpg"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={2 / 3}

            />
          </div>

          <Card.Title>Susan van den Heever</Card.Title>
          <Card.Body><strong>Principal Investigator</strong><br />University Distinguished Professor <br />Colorado State University</Card.Body>
        </Card>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A black and white headshot of Ziad Haddad"
              src="../../images/people/ziad_haddad_jpl.jpeg"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={2 / 3}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>
          <Card.Title>Ziad Haddad</Card.Title>
          <Card.Body><strong>Deputy Principal Investigator</strong><br />Radar Science Group Supervisor <br />Jet Propulsion Laboratory, NASA</Card.Body>
        </Card>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A picture of Simone Tanelli"
              src="../../images/people/simone_tanelli_jpl.jpg"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={2 / 3}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>
          <Card.Title>Simone Tanelli</Card.Title>
          <Card.Body><strong>Project Scientist</strong><br />Principal Engineer <br />Jet Propulsion Laboratory, NASA</Card.Body>
        </Card>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A placeholder image for a person"
              src="../../images/people/yunjin_kim_jpl.jpg"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={2 / 3}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>
          <Card.Title>Yunjin Kim</Card.Title>
          <Card.Body><strong>Project Manager</strong><br />Directorate Engineer <br />Jet Propulsion Laboratory, NASA</Card.Body>
        </Card>
      </Row>


      <h2>Science Team</h2>
      <Row xs={1} md={5} className='g-4'>
        {render_sciteam(sciteam, data)}
      </Row>
      <h2>Industry Partners</h2>
      <Row xs={1} md={3} className='g-4'>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="The logo for Tendeg"
              src="../../images/tendeg_logo.png"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={3 / 2}
              transformOptions={{ fit: "contain" }}
              backgroundColor="transparent"


            />
          </div>

          <Card.Title>Tendeg</Card.Title>
          <Card.Body>Deployable Antenna</Card.Body>
        </Card>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="The logo for Blue Canyon Technologies"
              src="../../images/bct_logo.png"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={3 / 2}
              objectFit="contain"
              transformOptions={{ cropFocus: 'center' }}
              backgroundColor="transparent"

            />
          </div>

          <Card.Title>Blue Canyon Technologies</Card.Title>
          <Card.Body>Spacecraft and Mission Operations</Card.Body>
        </Card>

      </Row>
      <h2>Data Center</h2>
      <Row xs={1} md={5} className='g-4'>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A placeholder image for a person"
              src="../../images/people/phil_partain_cira.jpg"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={2 / 3}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>

          <Card.Title>Phil Partain</Card.Title>
          <Card.Body>Science Data Processing Center Lead<br /> Cooporative Institute for Research in the Atmosphere</Card.Body>
        </Card>
        <Card>
          <div className="card-img-top">
            <StaticImage
              alt="A placeholder image for a person"
              src="../../images/people/amy_burzynski_cira.jpg"
              layout="constrained"
              placeholder="blurred"
              objectPosition="0 0"
              aspectRatio={2 / 3}
              transformOptions={{ cropFocus: 'center' }}

            />
          </div>

          <Card.Title>Amy Burzynski</Card.Title>
          <Card.Body>Science Data Processing Center Co-Lead<br /> Cooporative Institute for Research in the Atmosphere</Card.Body>
        </Card>

      </Row>


      <h2>International Collaborators</h2>
      <Row xs={1} md={5} className='g-4'>
        {render_intl_collabs(intl_collabs, data)}
      </Row>

      <h2>Advisory Board</h2>
      <Row xs={1} md={5} className='g-4'>
        {render_advisory_board(advisory_board, data)}
      </Row>

    </Layout>

  )
}

export const query = graphql`
query people_image_query {
  allPeopleImageListJson {
    nodes {
      slug
      id
      alt_text
      path {
        childImageSharp{
          gatsbyImageData(
            width:400
            placeholder: BLURRED
            aspectRatio: 0.66
            quality:80

          )
        }
      }
    }
  }
}`;

export default MissionTeamPage

